<!-- resources/js/Components/PWAInstallCard.vue -->
<template>
  <div>
    <div v-if="showCard" class="pwa-card-container">
      <div class="card pwa-card">
        <div class="card-header">
          <h5 class="card-title mb-0">Install My StoryPro AI</h5>
        </div>
        <div class="card-body">
          <p class="card-text">
            Install My StoryPro AI as a Progressive Web App (PWA) for quick access and a native app-like experience!
          </p>
          <p class="text-muted small mt-2">
            <i class="fas fa-info-circle me-1"></i>
            Installation may vary by browser. 
            <a href="#" @click.prevent="showCompatibilityInfo">Learn more</a>
          </p>
          <div class="row mb-2">
            <div class="col-auto">
              <button @click="installPWA" class="btn btn-primary me-2" :disabled="!isInstallable">Install Now</button>
            </div>
            <div class="col-auto">
              <button @click="dismissCard" class="btn btn-outline-secondary">Maybe Later</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Compatibility Info Modal -->
    <div class="modal fade" id="compatibilityModal" tabindex="-1" aria-labelledby="compatibilityModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="compatibilityModalLabel">
              Browser Compatibility Information
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>My StoryPro AI works best with modern browsers like <strong>Chrome</strong> and <strong>Edge</strong>. Some features may be limited in other browsers.</p>
            <p><strong>Note for Firefox Users:</strong> On desktop platforms, Firefox offers limited PWA support. For the best experience, we recommend using Chrome or Edge.</p>
            <p><strong>iOS Users:</strong> Use the "Add to Home Screen" option in Safari to install My StoryPro AI as a PWA.</p>
            <p>If you encounter issues during installation:</p>
            <ul>
              <li>- Ensure your browser is up to date.</li>
              <li>- Try using a different browser.</li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
  data() {
    return {
      showCard: false,
      deferredPrompt: null,
      isInstallable: false,
      compatibilityModal: null,
    };
  },
  mounted() {
    if (!this.getCookie('pwa_install_card_shown')) {
      window.addEventListener('beforeinstallprompt', this.captureInstallPrompt);
    }

    // Überprüfen, ob das Ereignis bereits global gespeichert wurde und ob cookie gesetzt wurde
  if (window.deferredPrompt && !this.getCookie('pwa_install_card_shown')) {
    this.deferredPrompt = window.deferredPrompt;
    this.isInstallable = true;
    this.showCard = true;
  }


    window.addEventListener('appinstalled', this.handleAppInstalled);
    this.compatibilityModal = new Modal(document.getElementById('compatibilityModal'));
  },
  beforeUnmount() {
    window.removeEventListener('beforeinstallprompt', this.captureInstallPrompt);
    window.removeEventListener('appinstalled', this.handleAppInstalled);
  },
  methods: {
    captureInstallPrompt(e) {
      e.preventDefault();
      this.deferredPrompt = e;
      window.deferredPrompt = e; // Speichern im globalen Fensterobjekt
      this.isInstallable = true;
      this.showCard = true;
      //console.log('beforeinstallprompt event captured');
    },
    handleAppInstalled() {
      //console.log('PWA wurde installiert');
      this.isInstallable = false;
      this.dismissCard();
    },
    async installPWA() {
      if (!this.deferredPrompt) {
        //console.log('Installation prompt not available');
        return;
      }

      this.deferredPrompt.prompt();

      try {
        const { outcome } = await this.deferredPrompt.userChoice;
        console.log(`User response to the install prompt: ${outcome}`);

        if (outcome === 'accepted') {
          //console.log('User accepted the install prompt');
        } else {
          //console.log('User dismissed the install prompt');
        }
      } catch (error) {
        console.error('Error during installation:', error);
      }

      this.deferredPrompt = null;
      window.deferredPrompt = null; // Zurücksetzen des globalen Prompts
      this.isInstallable = false;
      this.dismissCard();
    },
    dismissCard() {
      this.setCookie('pwa_install_card_shown', 'true', 30);
      this.showCard = false;
    },
    showCompatibilityInfo() {
      this.compatibilityModal.show();
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/; SameSite=Lax; Secure";
    },
    getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
  },
};
</script>

<style scoped>
.pwa-card-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1030;
}

.pwa-card {
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
